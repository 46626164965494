.swal2-title {
  line-height: 40px !important;
}

.uploader-style {
  width: 100% !important;
  max-width: 100% !important;
}

::-ms-reveal {
  display: none;
}
